import "./steps.scss";

const EditBusinessStepOne = ({ handleChange, business, handleGotoSteps }) => {
  const handleNext = () => {
    handleGotoSteps(1);
  };

  console.log("business", business);

  return (
    <form>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Register Business form Step One</h4>
            </div>
            {business?.paymentStatus === "paid" ? (
              <div className="card-body">
                <div className="basic-form">
                  <div className="row">
                    <div className="form-group mb-3">
                      <label className="text-dark">Name of Business</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="nameOfBusiness"
                        value={business.nameOfBusiness}
                        id="nameOfBusiness"
                        placeholder="Business name"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="nameOfBusiness" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Address 1</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        value={business.address1}
                        name="address1"
                        id="address1"
                        placeholder="Address 1"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="addressOne" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Address 2</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="address2"
                        id="address2"
                        value={business.address2}
                        placeholder="Address 2"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="addressTwo" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">City</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="city"
                        value={business.city}
                        id="city"
                        readOnly
                        placeholder="City"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="city" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">P.O Box/Zip code</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="postBox"
                        value={business.postBox}
                        id="postBox"
                        placeholder="Post Box"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="postBox" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Country</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="country"
                        value={business.country}
                        id="country"
                        placeholder="Country"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="country" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Phone Number</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="phoneNumber"
                        value={business.phoneNumber}
                        id="phoneNumber"
                        placeholder="Phone Number"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="telephone" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Email</label>
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="email"
                        value={business.email}
                        id="email"
                        placeholder="Email"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="email" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Website</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="website"
                        value={business.website}
                        id="website"
                        placeholder="Website"
                        readOnly
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="website" component="span" className="error-message" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="basic-form">
                  <div className="row">
                    <div className="form-group mb-3">
                      <label className="text-dark">Name of Business</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="nameOfBusiness"
                        value={business.nameOfBusiness}
                        id="nameOfBusiness"
                        placeholder="Business name"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="nameOfBusiness" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Address 1</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        value={business.address1}
                        name="address1"
                        id="address1"
                        placeholder="Address 1"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="addressOne" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Address 2</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="address2"
                        id="address2"
                        value={business.address2}
                        placeholder="Address 2"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="addressTwo" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">City</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="city"
                        value={business.city}
                        id="city"
                        placeholder="City"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="city" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">P.O Box/Zip code</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="postBox"
                        value={business.postBox}
                        id="postBox"
                        placeholder="Post Box"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="postBox" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Country</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="country"
                        value={business.country}
                        id="country"
                        placeholder="Country"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="country" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Phone Number</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="phoneNumber"
                        value={business.phoneNumber}
                        id="phoneNumber"
                        placeholder="Phone Number"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="telephone" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Email</label>
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="email"
                        value={business.email}
                        id="email"
                        placeholder="Email"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="email" component="span" className="error-message" /> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-dark">Website</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="website"
                        value={business.website}
                        id="website"
                        placeholder="Website"
                      />
                      <div className="error-message">
                        {/* <ErrorMessage name="website" component="span" className="error-message" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="text-end toolbar toolbar-bottom p-2">
        <button
          type="submit"
          className="btn btn-primary sw-btn-next"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default EditBusinessStepOne;
