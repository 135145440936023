import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { RegisterBusinessSchema } from "../../../Validations/RegisterBusinessValidations";
import { getTariff } from "../../../services/businessService";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import { ClearIndicator, ClearIndicatorStyles } from "./CustomMultiSelect";
import Select from "react-select";

const EditBusinessStepTwo = ({
  handleChange,
  formErrors,
  fullOwned,
  setBusiness,
  business,
}) => {
  const [loading, setLoading] = useState(false);
  const [tariff, setTariff] = useState([]);
  const [tarifOptionsState, setTarifOptionState] = useState([]);
  const [total, setTotal] = useState();
  const tarifOptions = [];

  const getTariffes = async () => {
    try {
      setLoading(true);
      const response = await getTariff();
      setLoading(false);
      setTariff(response.data);
      response?.data.map((option) => {
        tarifOptions.push({
          value: option.name,
          label: option.name,
          amount: option.amount,
        });
      });

      setTarifOptionState(tarifOptions);
    } catch (error) {
      setLoading(false);
    }
  };

  const sum = (accumulator, currentValue) => {
    const numberString = currentValue.replace("D", "").replace(",", "");
    const number = parseInt(numberString, 10);
    return accumulator + number;
  };

  const handleSelectChange = (value) => {
    let newArray = [];
    let amount = [];

    value.forEach(function (item) {
      amount.push(item.amount);
      newArray.push(item.value);
    });

    const totalAmount = amount?.reduce(sum, 0);

    setBusiness({
      ...business,
      natureOfBusiness: newArray,
      totalAmount: totalAmount,
    });

    setTotal(totalAmount);
  };

  // React.useEffect(() => {
  //   getTariffes();
  // }, []);

  React.useEffect(() => {
    getTariffes();
    if (!business.fullOwned) {
      setBusiness({
        ...business,
        fullOwned: business.fullOwned,
      });
    }
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  //   const businessData = {
  //     "natureOfBusiness": business.natureOfBusiness

  // };

  // businessData.natureOfBusiness = JSON.parse(businessData.natureOfBusiness).map(item => ({ value: item }));

  // console.log("nature of business",businessData.natureOfBusiness);

  // const defaultValue = business.natureOfBusiness.map((value) =>
  //   tarifOptionsState.find((option) => option.value === value)
  // );

  return (
    <Fragment>
      <form>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Register Business form Step Two</h4>
              </div>
              {business?.paymentStatus === "paid" ? (
                <div className="card-body">
                  <div className="basic-form">
                    <div className="row">
                      <div className="form-group mb-3">
                        <label className="text-dark">
                          Is it a group company?
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          name="fullOwned"
                          id="fullOwned"
                          value={business.groupCompany}
                          disabled
                        >
                          <option value="">Select status</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>

                      {business.groupCompany === "yes" && (
                        <div>
                          <div className="form-group mb-3">
                            <label className="text-dark">
                              Parent Company (Full legal name)
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="parentCompany"
                              id="parentCompany"
                              value={business.parentCompany}
                              placeholder="Parent Company"
                              readOnly
                            />
                            {formErrors.city && (
                              <div className="text-primary">
                                {formErrors.parentCompany}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label
                              htmlFor="subsidiariesAndState"
                              className="text-dark"
                            >
                              Subsidiaries and State if partially, full owned
                            </label>
                            <select
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="subsidiariesAndState"
                              id="subsidiariesAndState"
                              value={business.subsidiariesAndState}
                              disabled
                            >
                              <option value="">Select Subsidiaries</option>
                              <option value="partially owned">
                                Partially Owned
                              </option>
                              <option value="fully owned">Fully Owned</option>
                            </select>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-dark">
                              Expert area of business
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="experteAreaOfBusiness"
                              id="experteAreaOfBusiness"
                              value={business.experteAreaOfBusiness}
                              placeholder=""
                              readOnly
                            />
                            {formErrors.city && (
                              <div className="text-primary">
                                {formErrors.experteAreaOfBusiness}
                              </div>
                            )}
                          </div>

                          <div className="border p-3">
                            <div className="form-group mb-3 col-md-6">
                              <label className="text-dark">
                                Type of Business
                              </label>
                              <select
                                className="form-control form-control-sm"
                                onChange={handleChange}
                                name="typeOfBusiness"
                                id="typeOfBusiness"
                                value={business.typeOfBusiness}
                                disabled
                              >
                                <option value="">Type of Business</option>
                                <option value="partnership">Partnership</option>
                                <option value="sole proprietorship">
                                  Sole Proprietorship
                                </option>
                                <option value="limited liability company">
                                  Limited Liability Company
                                </option>
                              </select>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-group mb-3 col-md-6">
                                <label
                                  htmlFor="natureOfBusiness"
                                  className="text-dark"
                                >
                                  Nature of Business
                                </label>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={{ ClearIndicator }}
                                  styles={{
                                    clearIndicator: ClearIndicatorStyles,
                                  }}
                                  isMulti
                                  options={tarifOptionsState}
                                  onChange={(value) =>
                                    handleSelectChange(value)
                                  }
                                  value={business.tarifOptionState}
                                  name="natureOfBusiness"
                                  isDisabled={true}
                                />
                              </div>
                              <div>
                                <p>
                                  {total
                                    ? `Total Amount: D${total.toLocaleString()}`
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {business.groupCompany === "no" && (
                        <div>
                          <div className="form-group mb-3">
                            <label className="text-dark">
                              Expert area of business
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="experteAreaOfBusiness"
                              id="experteAreaOfBusiness"
                              value={business.experteAreaOfBusiness}
                              readOnly
                            />
                          </div>

                          <div className="border p-3">
                            <div className="form-group mb-3 col-md-6">
                              <label className="text-dark">
                                Type of Business
                              </label>

                              <select
                                className="form-control form-control-sm"
                                onChange={handleChange}
                                name="typeOfBusiness"
                                id="typeOfBusiness"
                                value={business.typeOfBusiness}
                                disabled={true}
                              >
                                <option value="">Type of Business</option>
                                <option value="partnership">Partnership</option>
                                <option value="sole proprietorship">
                                  Sole Proprietorship
                                </option>
                                <option value="limited liability company">
                                  Limited Liability Company
                                </option>
                              </select>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-group mb-3 col-md-6">
                                <label className="text-dark">
                                  Nature of Business
                                </label>

                                <Select
                                  closeMenuOnSelect={false}
                                  isMulti
                                  options={tarifOptionsState}
                                  onChange={(value) =>
                                    handleSelectChange(value)
                                  }
                                  value={business?.natureOfBusiness?.map(
                                    (nature) =>
                                      ({
                                        label: nature,
                                        value: nature,
                                        amount:
                                          tarifOptionsState.find(
                                            (option) => option.value === nature
                                          )?.amount || "D0",
                                      } || [])
                                  )}
                                  isDisabled={true}
                                  // defaultValue={business?.natureOfBusiness?.map(nature => ({
                                  //   label: nature,
                                  //   value: nature
                                  // }) || [])}
                                />
                              </div>
                              <div>
                                <p>
                                  {total
                                    ? `Total Amount: D${total.toLocaleString()}`
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body">
                  <div className="basic-form">
                    <div className="row">
                      <div className="form-group mb-3">
                        <label className="text-dark">
                          Is it a group company?
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          name="fullOwned"
                          id="fullOwned"
                          value={business.groupCompany}
                        >
                          <option value="">Select status</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>

                      {business.groupCompany === "yes" && (
                        <div>
                          <div className="form-group mb-3">
                            <label className="text-dark">
                              Parent Company (Full legal name)
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="parentCompany"
                              id="parentCompany"
                              value={business.parentCompany}
                              placeholder="Parent Company"
                            />
                            {formErrors.city && (
                              <div className="text-primary">
                                {formErrors.parentCompany}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label
                              htmlFor="subsidiariesAndState"
                              className="text-dark"
                            >
                              Subsidiaries and State if partially, full owned
                            </label>
                            <select
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="subsidiariesAndState"
                              id="subsidiariesAndState"
                              value={business.subsidiariesAndState}
                            >
                              <option value="">Select Subsidiaries</option>
                              <option value="partially owned">
                                Partially Owned
                              </option>
                              <option value="fully owned">Fully Owned</option>
                            </select>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-dark">
                              Expert area of business
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="experteAreaOfBusiness"
                              id="experteAreaOfBusiness"
                              value={business.experteAreaOfBusiness}
                              placeholder=""
                            />
                            {formErrors.city && (
                              <div className="text-primary">
                                {formErrors.experteAreaOfBusiness}
                              </div>
                            )}
                          </div>

                          <div className="border p-3">
                            <div className="form-group mb-3 col-md-6">
                              <label className="text-dark">
                                Type of Business
                              </label>
                              <select
                                className="form-control form-control-sm"
                                onChange={handleChange}
                                name="typeOfBusiness"
                                id="typeOfBusiness"
                                value={business.typeOfBusiness}
                              >
                                <option value="">Type of Business</option>
                                <option value="partnership">Partnership</option>
                                <option value="sole proprietorship">
                                  Sole Proprietorship
                                </option>
                                <option value="limited liability company">
                                  Limited Liability Company
                                </option>
                              </select>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-group mb-3 col-md-6">
                                <label
                                  htmlFor="natureOfBusiness"
                                  className="text-dark"
                                >
                                  Nature of Business
                                </label>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={{ ClearIndicator }}
                                  styles={{
                                    clearIndicator: ClearIndicatorStyles,
                                  }}
                                  isMulti
                                  options={tarifOptionsState}
                                  onChange={(value) =>
                                    handleSelectChange(value)
                                  }
                                  value={business.tarifOptionState}
                                  name="natureOfBusiness"
                                />
                              </div>
                              <div>
                                <p>
                                  {total
                                    ? `Total Amount: D${total.toLocaleString()}`
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {business.groupCompany === "no" && (
                        <div>
                          <div className="form-group mb-3">
                            <label className="text-dark">
                              Expert area of business
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              onChange={handleChange}
                              name="experteAreaOfBusiness"
                              id="experteAreaOfBusiness"
                              value={business.experteAreaOfBusiness}
                            />
                          </div>

                          <div className="border p-3">
                            <div className="form-group mb-3 col-md-6">
                              <label className="text-dark">
                                Type of Business
                              </label>

                              <select
                                className="form-control form-control-sm"
                                onChange={handleChange}
                                name="typeOfBusiness"
                                id="typeOfBusiness"
                                value={business.typeOfBusiness}
                              >
                                <option value="">Type of Business</option>
                                <option value="partnership">Partnership</option>
                                <option value="sole proprietorship">
                                  Sole Proprietorship
                                </option>
                                <option value="limited liability company">
                                  Limited Liability Company
                                </option>
                              </select>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-group mb-3 col-md-6">
                                <label className="text-dark">
                                  Nature of Business
                                </label>

                                <Select
                                  closeMenuOnSelect={false}
                                  isMulti
                                  options={tarifOptionsState}
                                  onChange={(value) =>
                                    handleSelectChange(value)
                                  }
                                  value={business?.natureOfBusiness?.map(
                                    (nature) =>
                                      ({
                                        label: nature,
                                        value: nature,
                                        amount:
                                          tarifOptionsState.find(
                                            (option) => option.value === nature
                                          )?.amount || "D0",
                                      } || [])
                                  )}
                                  // defaultValue={business?.natureOfBusiness?.map(nature => ({
                                  //   label: nature,
                                  //   value: nature
                                  // }) || [])}
                                />
                              </div>
                              <div>
                                <p>
                                  {total
                                    ? `Total Amount: D${total.toLocaleString()}`
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default EditBusinessStepTwo;
