import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { RegisterBusinessSchema } from "../../../Validations/RegisterBusinessValidations";

import { Col } from "react-bootstrap";

const EditBusinessStepThree = ({
  handleChange,
  formErrors,
  business,
  setBusiness,
}) => {
  const [businessCertificate, setBusinessCertificate] = useState("");
  const [tinCertificate, setTinCertificate] = useState("");
  const [idCertificate, setIdCertificate] = useState("");
  const [businessError, setBusinessError] = useState("");
  const [tinError, setTinError] = useState("");
  const [idError, setIdError] = useState("");

  const handleBusinessFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setBusinessError("File size exceeds the limit of 10MB");
      } else {
        setBusinessCertificate(file.name);
        setBusiness({ ...business, [event.target.id]: file });
        setBusinessError("");
      }
    }
  };

  const handleTinFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setTinError("File size exceeds the limit of 10MB");
      } else {
        setTinCertificate(file.name);
        setBusiness({ ...business, [event.target.id]: file });
        setTinError("");
      }
    }
  };

  const handleIdFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setIdError("File size exceeds the limit of 10MB");
      } else {
        setIdCertificate(file.name);
        setBusiness({ ...business, [event.target.id]: file });
        setIdError("");
      }
    }
  };

  return (
    <form>
      <div className="row mt-3">
        <div className="col-xl-12 border">
          {business?.paymentStatus === "paid" ? (
            <div className="row mt-3 mb-3" style={{ justifyContent: "center" }}>
              <div className="col-xl-3 col-sm-3 ">
                <p className="mb-2 font-w600">Business Certificate</p>
                <div className=" text-center border pt-2">
                  <label for="businessCertificate">
                    <svg
                      onChange={handleBusinessFileChange}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-75 h-75 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-sm d-none"
                    onChange={handleBusinessFileChange}
                    name="businessCertificate"
                    id="businessCertificate"
                    disabled={true}
                  />
                  <p
                    className="mb-0 text-wrap text-dark font-w600"
                    style={{ fontSize: "10px" }}
                  >
                    click box to upload
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "10px" }}
                  >
                    "Maximum file size 10md"
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {businessError ? businessError : businessCertificate}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-3 ">
                <p className="mb-2 font-w600">Tin Certificate</p>
                <div className=" text-center border pt-2">
                  <label for="tinCertificate">
                    <svg
                      onChange={handleTinFileChange}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-75 h-75 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-sm d-none"
                    onChange={handleTinFileChange}
                    name="tinCertificate"
                    id="tinCertificate"
                    disabled={true}
                  />
                  <p
                    className="mb-0 text-wrap text-dark font-w600"
                    style={{ fontSize: "10px" }}
                  >
                    click box to upload
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "10px" }}
                  >
                    Maximum file size 10md
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {tinError ? tinError : tinCertificate}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-3 ">
                <p className="mb-2 font-w600">Personal ID Card/Passport</p>
                <div className=" text-center border pt-2">
                  <label for="idCertificate">
                    <svg
                      onChange={handleIdFileChange}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-75 h-75 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-sm d-none"
                    onChange={handleIdFileChange}
                    name="idCertificate"
                    id="idCertificate"
                    disabled={true}
                  />
                  <p
                    className="mb-0 text-wrap text-dark font-w600"
                    style={{ fontSize: "10px" }}
                  >
                    click box to upload
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "10px" }}
                  >
                    Maximum file size 10md
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {idError ? idError : idCertificate}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3 mb-3" style={{ justifyContent: "center" }}>
              <div className="col-xl-3 col-sm-3 ">
                <p className="mb-2 font-w600">Business Certificate</p>
                <div className=" text-center border pt-2">
                  <label for="businessCertificate">
                    <svg
                      onChange={handleBusinessFileChange}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-75 h-75 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-sm d-none"
                    onChange={handleBusinessFileChange}
                    name="businessCertificate"
                    id="businessCertificate"
                  />
                  <p
                    className="mb-0 text-wrap text-dark font-w600"
                    style={{ fontSize: "10px" }}
                  >
                    click box to upload
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "10px" }}
                  >
                    "Maximum file size 10md"
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {businessError ? businessError : businessCertificate}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-3 ">
                <p className="mb-2 font-w600">Tin Certificate</p>
                <div className=" text-center border pt-2">
                  <label for="tinCertificate">
                    <svg
                      onChange={handleTinFileChange}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-75 h-75 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-sm d-none"
                    onChange={handleTinFileChange}
                    name="tinCertificate"
                    id="tinCertificate"
                  />
                  <p
                    className="mb-0 text-wrap text-dark font-w600"
                    style={{ fontSize: "10px" }}
                  >
                    click box to upload
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "10px" }}
                  >
                    Maximum file size 10md
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {tinError ? tinError : tinCertificate}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-3 ">
                <p className="mb-2 font-w600">Personal ID Card/Passport</p>
                <div className=" text-center border pt-2">
                  <label for="idCertificate">
                    <svg
                      onChange={handleIdFileChange}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-75 h-75 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-sm d-none"
                    onChange={handleIdFileChange}
                    name="idCertificate"
                    id="idCertificate"
                  />
                  <p
                    className="mb-0 text-wrap text-dark font-w600"
                    style={{ fontSize: "10px" }}
                  >
                    click box to upload
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "10px" }}
                  >
                    Maximum file size 10md
                  </p>
                  <p
                    className="mb-0 text-wrap text-dark mb-2"
                    style={{ fontSize: "12px" }}
                  >
                    {idError ? idError : idCertificate}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default EditBusinessStepThree;
